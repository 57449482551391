<template>
    <font-awesome-icon icon="download" @click="confirmarEmitir()" size="lg" class="icon-button p-text-primary" title="Emitir Certificado" />
</template>

<script>
import ParticipanteService from './services';
export default {
    props: {
        participante: {
            type: Object,
        },
    },

    methods: {
        confirmarEmitir() {
            this.$confirm.require({
                message: `Tem certeza que deseja emitir o certificado?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.downloadCertificado();
                },
            });
        },

        downloadCertificado() {
            this.$store.dispatch('addRequest');
            ParticipanteService.downloadCertificado(this.participante.participanteId).then((response) => {
                if (response?.success) {
                    this.$download(response.data, `Certificado_${this.participante.cnpj}.pdf`);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Participantes',
                        detail: 'Erro ao realizar download do Certificado',
                        life: 3000,
                    });
                }
                this.$emit('obterParticipantesAtualizados');
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
