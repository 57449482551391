<template>
    <Button label="Inserir Relatório" icon="pi pi-plus" @click="toInserir()"></Button>
</template>

<script>
export default {
    methods: {
        toInserir() {
            this.$router.push({
                name: `BrasilMais_Participantes_Inserir`,
            });
        },
    },
};
</script>
