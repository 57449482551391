<template>
    <font-awesome-icon icon="trash" @click="confirmar()" size="lg" class="icon-button p-text-danger" title="Remover" />
</template>

<script>
import ParticipanteService from './services';

export default {
    props: {
        participante: {
            type: Object,
        },
    },

    emits: ['obterParticipantesAtualizados'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja remover o participante?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.remover();
                },
            });
        },

        remover() {
            this.$store.dispatch('addRequest');
            ParticipanteService.remover(this.participante.participanteId).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Participantes',
                        detail: 'Participante removido com sucesso',
                        life: 3000,
                    });
                    this.$emit('obterParticipantesAtualizados');
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Participantes',
                        detail: response.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
